import React, { useState, useEffect } from 'react';
import { Spin, message, Form, Input, Image, Select, Button } from 'antd';
import { useLazyGet, usePost } from '../utils/api';
import { useHistory, useLocation } from 'react-router-dom';
import ErrorPage from './Error';
import { useStore } from '../context/GlobalStore';
import MultipleUpload from '../components/MultipleUpload';
import { CLEAR_FILE_LIST } from '../context/ContextActions';
import { Progress } from 'antd';

const Home = () => {

    const location = useLocation();
    const { post: uploadPost, isLoading: postLoading } = usePost('/data/upload');
    const { get: companyGet } = useLazyGet('/data/company');
    const [error, setError] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [pageLoading, setPageLoading] = useState(true);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [failed, setFailed] = useState(false);

    const [form] = Form.useForm();
    const { state: globalState, dispatch } = useStore();

    const uploadConfig = {
        onUploadProgress: progressEvent => {
            const {loaded, total} = progressEvent;
            let percent = Math.floor( (loaded * 100) / total )
            // console.log( `${loaded}kb of ${total}kb | ${percent}%` );

            if( percent < 100 ){
                setUploadPercentage(percent);
            }
        }
    }


    useEffect(() => {
        async function fetchData() {
            try {
                let companyKey = location.pathname.replace('/', '');
                let response = await companyGet({ companyKey });
                setCompanyName(response);
                setError(false);
                setPageLoading(false);
            }
            catch {
                setError(true);
                setPageLoading(false);
            }
        }
        dispatch({ type: CLEAR_FILE_LIST });
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUpload = async (values) => {
        setFailed(false);
        setUploadPercentage(0);

        // if(globalState.uploadedFiles.length < 1){
        //     form.setFields({
        //         upload: {
        //           errors: [new Error('Choose at least 1 file')],
        //         },
        //       });
        //       return;
        // }

        let formData = new FormData();
        let companyKey = location.pathname.replace('/', '');
        formData.append('companyKey', companyKey);
        formData.append('companyPass', values.companyPass);
        formData.append('uploadType', values.uploadType);

        if (Array.isArray(globalState.uploadedFiles)) {
            globalState.uploadedFiles.forEach(file => {
                formData.append('files', file);
            });
        }

        try {
            await uploadPost(formData, uploadConfig);
            setUploadPercentage(100);
            message.info('Upload Successful', 5);
            form.resetFields();
            dispatch({ type: CLEAR_FILE_LIST });
        }
        catch (err) {
            setFailed(true);
            message.error(`Data upload failed - ${err}. Please Try again`, 5)
        }
    }

    const resetFailed = () => {
        setFailed(false);
        setUploadPercentage(0);
    };


    return (
        pageLoading ?
            <div className='page-center'>
                <Spin size='large' />
            </div>
            :
            error ?
                <div>
                    <ErrorPage />
                </div>
                :
                <div className='page-center-border'>
                    <div className='mb-3'>
                        <div className='text-center'>
                            <Image width={200} preview={false} src='/logo.png' />
                        </div>

                        <h5 className='m-5 text-center'>{companyName}</h5>
                        <Form onFinish={handleUpload} form={form}>
                            <Form.Item rules={[{ required: true, message: 'Select an Upload Type' }]} name="uploadType">
                                <Select style={{ minWidth: '250px' }} placeholder='Upload Type' onChange={resetFailed}>
                                    <Select.Option value="OOTD">Overriding One Time Dump</Select.Option>
                                    <Select.Option value="AD">Additive Dump</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item rules={[{ required: true, message: 'Enter the Company Pass' }]} name="companyPass">
                                <Input style={{ minWidth: '250px' }} type='password' placeholder="Company Pass" onChange={resetFailed}/>
                            </Form.Item>

                            <Form.Item name='upload' rules={[{ required: true, message: 'Choose one or more files' }, {
                                validator(rule, value, callback) {
                                    if (globalState.uploadedFiles.length < 1) {
                                        callback('');
                                    }
                                    else {
                                        callback();
                                    }

                                }
                            }]}>
                                <MultipleUpload maxFileSize={5 * 1024 * 1024 * 1024} title="Select Data Files" style={{ width: '100%' }}  onChange={resetFailed} />
                            </Form.Item>
                            <div className='text-center mb-2'>
                                { uploadPercentage > 0 && (failed 
                                    ? <Progress type="circle" percent={uploadPercentage} width={80} status="exception" /> 
                                    : <Progress percent={uploadPercentage} type="circle" width={80} />) }
                            </div>

                            <Form.Item className='text-center'>
                                <Button loading={postLoading} type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>

    );
};

export default Home;