import {
  ADD_FILE,
  REMOVE_FILE,
  CLEAR_FILE_LIST
} from "./ContextActions";

const uploadedFiles = (state = [], action) => {
  switch (action.type) {
    case ADD_FILE:
      return [...state, action.payload];
    case REMOVE_FILE:
      var newUploadedFileList = state.slice();
      newUploadedFileList.splice(action.payload, 1);
      return newUploadedFileList;
    case CLEAR_FILE_LIST:
      return [];
    default:
      return state;
  }
};

// const updateUI = (state = [], action) => {
//   switch (action.type) {
//     case ADD_DATA:
//       let data = action.data;
//       return data;
//     default:
//       return state;
//   }
// };

var reducers = {
  uploadedFiles,
  // updateUI,
};

export default reducers;
