import React from 'react';
import { Image } from 'antd';

const Error = () => {
    return (
        <div className='text-center'>
            <h3 className='mt-1'>Page Not Found</h3>
            <Image width={500} height={600} preview={false} alt='404 Error - Page not Found' src='/404.svg' />
        </div>
    )
}

export default Error;