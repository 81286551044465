import React from 'react';
import { Upload, Button, message } from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import {useStore} from '../context/GlobalStore';
import {ADD_FILE, REMOVE_FILE} from '../context/ContextActions';

// It is very importnat that the file storage state be cleared before displaying this
// component in the UI. Clear by dispatching the action 'CLEAR_FILE_LIST'

const MultipleUpload = (props) => {
    const {state: globalState, dispatch} = useStore();
    let fileList = globalState.uploadedFiles;

    const validateFileSize = (file) => {
        if(props.maxFileSize === null || props.maxFileSize === undefined)
            return true;
        if(file.size > props.maxFileSize)
            return false;
        return true;
    };

    const validateFileType = (file) => {
        if(props.supportedFileTypes === null || props.supportedFileTypes === undefined)
            return true;
        if(props.supportedFileTypes.includes(file.type))
            return true;
        return false;
    };

    const validateMaxFileCount = () => {    
        if(props.maxFileCount === undefined 
            || props.maxFileCount === null || fileList.length < props.maxFileCount)
            return true;
        return false;
    };


    props = {
        ...props,
        onRemove: file => {            
            const index = fileList.indexOf(file);
            dispatch({type: REMOVE_FILE, payload: index});
        },
        beforeUpload: file => {
            if(!validateMaxFileCount()){
                message.error(`Only ${props.maxFileCount} file(s) can be selected`);
                return;
            }
            if(!validateFileSize(file)){
                message.error(`File must not be greater than ${props.maxFileSize}MB`);
                return;
            }
            if(!validateFileType(file)){
                message.error("This type of file selected is not supported");
                return;
            }
            dispatch({type: ADD_FILE, payload: file});
            return false;
        },
        fileList,
    };

    return (
        <>
            <Upload {...props} multiple>
                <Button style={{ minWidth: '250px' }} icon={<UploadOutlined className="text-lg"/>} className="flex justify-center items-center"> {props.title ? props.title : 'Select File'}</Button>
            </Upload>
        </>
    );
}           

export default MultipleUpload;