import React, {createContext, useReducer, useContext} from 'react';
import reducers from './Reducers';
import {getInitialState, combineReducers} from './Helpers';

const initialState = getInitialState(reducers);
const StoreContext = createContext(initialState);

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(combineReducers(reducers), initialState);
  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => {
  const { state, dispatch } = useContext(StoreContext);
  return { state, dispatch };
};