import React, { Component } from 'react';
import { Route } from 'react-router';
import 'antd/dist/antd.css';
import './custom.css'
import { BrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import { StoreProvider } from './context/GlobalStore';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <StoreProvider>
                <Home/>
            </StoreProvider>            
        );
    }
}
