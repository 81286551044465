export function getInitialState(reducers) {
    const reducersCombined = {};
    for (let key in reducers) {
      reducersCombined[key] = reducers[key](undefined, {});
    }
    return reducersCombined;
  }
  
  export function combineReducers(reducers) {
    return (state = {}, action) => {
      const newState = {};
      for (let key in reducers) {
        newState[key] = reducers[key](state[key], action);
      }
      return newState;
    }
  }